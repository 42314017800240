<script>
import classifyBox from "./classify.vue"
import detectionBox from "./detection.vue"
export default {
  name: "index",
  components:{
    classifyBox,
    detectionBox
  },
  data(){
    return{
      projectInfo: {},
      cardTypeIndex: 1,
    }
  },
  methods:{
    getProjectName(value){
      this.projectInfo = Object.assign({},value)
    },
    getCardType(value){
      this.cardTypeIndex = value
    }
  }
}
</script>

<template>
  <div class="pesticide-page">
    <!-- 项目 -->
    <div class="pesticide-left">
      <classifyBox @projectName="getProjectName" @cardType="getCardType"/>
    </div>
    <!-- 检测 -->
    <div class="pesticide-right">
      <detectionBox :setProjectName="projectInfo" :setCardIndex="cardTypeIndex"/>
    </div>
  </div>

</template>

<style scoped lang="scss">
.pesticide-page{
  width: 100%;
  height: 90vh;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 20px;

  .pesticide-left{
    flex: 1;
    height: 100%;
    display: flex;
  }
  .pesticide-right{
    flex: 1.5;
    height: 100%;
    display: flex;
  }
}
</style>
